import axios from "axios";


export default {

  data: () => ({
    camp_dialog: false,
    user: {},

    cities: [
      'Melbourne',
      'Sydney',
      'Brisbane',
      'Perth',
      'Hobart',
      'Canberra',
      'Adelaide',
  ],
  countries: [
      'Australia',
  ],

    role: 1,


    // form
    name: '',
    email: '',
    phone: '',
    instagram_id: '',
    tiktok_id: '',
    country: 'Australia',
    city: 'Melbourne',
    // custom
    abn: '',
    business_name: '',
    venue_name: '',
    venue_address: '',
    website: '',
    logo: '',
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_user()
    this.role = localStorage.getItem('role')
  },

  methods: {
    async get_user() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/users/me',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          
          console.log(res.data.data.user);
          this.user = res.data.data.user

          // bus
          this.name = this.user.name
          this.email = this.user.email
          this.phone = this.user.phone
          this.instagram_id = this.user.instagramId.replace('https://instagram.com/','')
          this.tiktok_id = this.user.tiktokId ? this.user.tiktokId.replace('https://tiktok.com/','') : ''
          this.country = this.user.country
          this.city = this.user.city

          if (this.user.role === 2) {
            this.abn = this.user.details.abn
            this.business_name = this.user.details.name
            this.logo = this.user.details.logo

          }
          if (this.user.role === 1) {
            this.abn = this.user.details.abn
            this.venue_name = this.user.details.name
            this.logo = this.user.details.logo
            this.website = this.user.details.websiteLink.replace('https://','')
            this.venue_address = this.user.details.address

          }

        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async handleImageUpload(event, num) {
      const [file] = document.getElementById('logo').files
      if (file) {
        document.getElementById('img').src = URL.createObjectURL(file)
      }
    },




    async send_image() {

      const formData = new FormData();
      formData.append("image", document.getElementById('logo').files[0]);

      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: '/api/images',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        data: formData
      })
        .then((res) => {
          console.log(res.data);
          this.logo = res.data.data.filename
          localStorage.setItem('logo', this.logo)
          this.edit_user()
        })
        .catch((err) => {
          console.log(err);
          // this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          if (err.response.data.errors === 'empty') {
            this.edit_user()
          }
          else{
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

          }
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },


    validation() {
      console.log(this.email);
      console.log(this.abn);

      if (!this.email || !this.name || !this.phone || !this.instagram_id ||
        !this.country || !this.city
      ) {
        this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
      }
      else if (!this.email.includes('.com') || !this.email.includes('@')) {
        this.$store.commit('snackbar', { color: 'red', text: 'Email address is not valid.' })
      }
      else if (this.phone.length !== 10) {
        this.$store.commit('snackbar', { color: 'red', text: 'Phone number is not valid.' })
      }

      // venue
      else if (this.role === '1') {
        if (!this.venue_name || !this.venue_address || !this.website || !this.abn) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
          console.log(this.role);
        }
        else if (this.abn.length !== 11) {
          this.$store.commit('snackbar', { color: 'red', text: 'ABN must contain 11 digits.' })
        }
        else {
          if (this.role !== '3') {
            this.send_image()
          }
          else {
            this.edit_user()
          }

        }
      }

      // business
      else if (this.role === '2') {
        if (!this.business_name || !this.abn) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.abn.length !== 11) {
          this.$store.commit('snackbar', { color: 'red', text: 'ABN must contain 11 digits.' })
        }
        else {
          if (this.role !== '3') {
            this.send_image()
          }
          else {
            this.edit_user()
          }
        }
      }
      else {
        if (this.role !== '3') {
          this.send_image()
        }
        else {
          this.edit_user()
        }
      }

    },


    async edit_user() {

         console.log(this.email);
      console.log(this.abn);

      let details

      if (this.user.role === 3) {
        details = undefined
      }
      if (this.user.role === 2) {
        details = {
          name: this.business_name,
          logo: this.logo
        }
      }
      if (this.user.role === 1) {
        details = {
          name: this.venue_name,
          address: this.venue_address,
          logo: this.logo,
          websiteLink:'https://'+ this.website,
        }
      }


      this.$store.commit('progress_status', true)
      await axios({
        method: 'PUT',
        url: '/api/users/me',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        data: {
          name: this.name,
          country: this.country,
          city: this.city,
          instagramId:'https://instagram.com/'+ this.instagram_id,
          tiktokId:this.tiktok_id ? 'https://tiktok.com/' + this.tiktok_id : undefined,
          details
        }
      })
        .then((res) => {
          console.log(res.data);
          this.$store.commit('snackbar', { color: 'green', text: 'Edited successfully' })
          this.$router.push('/profile')

        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },
  }
};