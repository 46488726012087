import axios from "axios";


export default {

    data: () => ({
        start_dialog: true,
        finish_dialog: false,
        questions: [],
        question: {},
        answers: [],
        color: 'blue',
        total: 0,
        // start_time:'',
    }),

    mounted() {
        window.scrollTo(0, 0);
        this.questions = JSON.parse(sessionStorage.getItem('questions')) || []
        this.answers = JSON.parse(sessionStorage.getItem('answers')) || []

        if (this.answers.length !== 0) {
            this.start_dialog = false
            this.next_question()
        }

    },

    methods: {

        async get_questions() {
            this.$store.commit("progress_status", true);
            await axios({
                method: "GET",
                url: "/api/questions/random",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.questions = res.data.data.questions
                    this.start_dialog = false
                    sessionStorage.setItem('questions', JSON.stringify(this.questions))
                    sessionStorage.setItem('answers', JSON.stringify(this.answers))
                    // this.start_time = new Date()
                    sessionStorage.setItem('start_time',new Date())
                    this.next_question()
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit("snackbar", {
                        color: "red",
                        text: err.response.data.errors,
                    });
                })
                .finally(() => {
                    this.$store.commit("progress_status", false);
                });
        },

        check_answer(user_answer) {
            // user_answer.isCorrect ? this.color = 'green' : this.color = 'red'
            this.answers.push(user_answer.isCorrect ? 1 : 0)
            sessionStorage.setItem('answers', JSON.stringify(this.answers))


            let btn = document.getElementById('btn' + user_answer._id)

            user_answer.isCorrect ? btn.classList.add('green') : btn.classList.add('red')


            setTimeout(() => {
                user_answer.isCorrect ? btn.classList.remove('green') : btn.classList.remove('red')
                this.next_question()
            }, 300);
        },

        next_question() {
            // this.color = 'blue'
            this.questions = JSON.parse(sessionStorage.getItem('questions'))
            this.answers = JSON.parse(sessionStorage.getItem('answers'))
            console.log(this.answers);

            if (this.questions.length !== this.answers.length) {
                this.question = this.questions[+this.answers.length]

                // console.log(this.questions.length);
                // console.log(this.answers.length);
            }

            else {
                this.finish_dialog = true
                this.total = 0
                for (let i = 0; i < this.answers.length; i++) {
                    this.total += +this.answers[i]
                }
            }

        },

        async finish() {
            this.finish_dialog = false


            this.$store.commit("progress_status", true);
            await axios({
                method: "POST",
                url: "/api/questions/finish",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                },
                data: {
                    correct: this.total,
                    start : sessionStorage.getItem('start_time'),
                    end :new Date()
                }
            })
                .then((res) => {
                    console.log(res.data.data);
                    sessionStorage.clear()
                    this.$router.push('/my_energy')

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit("snackbar", {
                        color: "red",
                        text: err.response.data.errors,
                    });
                })
                .finally(() => {
                    this.$store.commit("progress_status", false);
                });

        },

        back(){
            this.start_dialog = false
            this.$router.go(-1)
        }

    }
};