import axios from "axios";


export default {

  data: () => ({
    venue : {},

  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_venue()
  },

  methods: {
    async get_venue() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/venues/' + this.$route.query.id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.venue = res.data.data.venue
          localStorage.setItem('venue_name' , this.venue.details.name)
          localStorage.setItem('venue_membership' , this.venue.membership)

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    open_link(link) {
      window.open(link, '_blank')
    },

    cut(num) {
      let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return x.toFixed(2)
    }
  }
};