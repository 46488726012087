import { render, staticRenderFns } from "./progressBar.vue?vue&type=template&id=2fb119b7&scoped=true&"
var script = {}
import style0 from "./progressBar.vue?vue&type=style&index=0&id=2fb119b7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb119b7",
  null
  
)

export default component.exports