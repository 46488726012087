import axios from "axios";


export default {


  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    camp_dialog: false,

    type: '',
    name: '',
    logo: '',
    insta_id: '',
    tiktok_id: '',

    // form
    title: '',
    link: '',
    platform: '',
    participant_count: '',
    participant_share: '',
    condation: '',

    end_date: '',
    d_end_date: '',

    voucher_type: '',
    free: '',
    discount: '',

    today: '',
    role: '',
  }),


  mounted() {
    window.scrollTo(0, 0);
    this.type = this.$route.query.type

    // convert date
    let tomarrow = new Date().getTime() + 24 * 60 * 60 * 1000
    this.today = new Date(tomarrow).toISOString().split('T')[0]

    this.name = localStorage.getItem('name')
    this.logo = localStorage.getItem('logo')
    this.insta_id = localStorage.getItem('instagramId')
    this.tiktok_id = localStorage.getItem('tiktokId')
    this.role = localStorage.getItem('role')

  },

  methods: {

    convert() {
      this.d_end_date = new Date(this.end_date).toLocaleDateString('en-AU')
    },


    validation() {

      if (!this.title || !this.end_date || !this.condation || !this.participant_count) {
        this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
      }
      else if (this.title.length > 70) {
        this.$store.commit('snackbar', { color: 'red', text: 'Title must contain less than 70 characters.' })

      }
      else if (this.condation.length > 400) {
        this.$store.commit('snackbar', { color: 'red', text: 'Conditions must contain less than 400 characters.' })

      }
      else if (this.participant_count.includes('.')) {
        this.$store.commit('snackbar', { color: 'red', text: 'participant_count' })
      }
      else if (this.role == 2 && this.participant_share < 0.02) {
        this.$store.commit('snackbar', { color: 'red', text: 'Payment per participant must be at least $0.02.' })
      }

      // 
      else if (this.type === '1') {

        if (!this.link || this.link.includes(' ') || !this.link.includes('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
        }
        else if (!this.participant_share) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.participant_share.startsWith('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'enter nubmer like 0.5' })
        }
        else if ( this.link.length > 100) {
          this.$store.commit('snackbar', { color: 'red', text: 'Link must contain less than 100 characters.' })
        }
        else {
          this.create_campaign()
        }
      }

      // 
      else if (this.type === '2') {
        if (!this.platform) {
          this.$store.commit('snackbar', { color: 'red', text: 'Which platform are you targeting?' })
        }
        else if (!this.participant_share) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.participant_share.startsWith('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'enter nubmer like 0.5' })
        }
        else {
          this.create_campaign()
        }
      }

      // 
      else if (this.type === '3') {
        if (!this.link || this.link.includes(' ') || !this.link.includes('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
        }
        else if (!this.participant_share) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.participant_share.startsWith('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'enter nubmer like 0.5' })
        }
        else if ( this.link.length > 100) {
          this.$store.commit('snackbar', { color: 'red', text: 'Link must contain less than 100 characters.' })
        }
        else {
          this.create_campaign()
        }
      }

      // 
      else if (this.type === '4') {
        if (this.link.includes(' ')) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
        }
        else if (!this.platform) {
          this.$store.commit('snackbar', { color: 'red', text: 'Which platform are you targeting?' })
        }
        else if (!this.participant_share) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.participant_share.startsWith('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'enter nubmer like 0.5' })
        }
        else if ( this.link.length > 100) {
          this.$store.commit('snackbar', { color: 'red', text: 'Link must contain less than 100 characters.' })
        }
        else {
          this.create_campaign()
        }
      }

      // 
      else if (this.type === '5') {
        if (!this.link || this.link.includes(' ') || !this.link.includes('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
        }
        else if (!this.platform) {
          this.$store.commit('snackbar', { color: 'red', text: 'Which platform are you targeting?' })
        }
        else if (!this.participant_share) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.participant_share.startsWith('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'enter nubmer like 0.5' })
        }
        else if ( this.link.length > 100) {
          this.$store.commit('snackbar', { color: 'red', text: 'Link must contain less than 100 characters.' })
        }
        else {
          this.create_campaign()
        }
      }

      // 
      else if (this.type === '6') {
        if (!this.link || this.link.includes(' ') || !this.link.includes('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
        }
        else if (!this.platform) {
          this.$store.commit('snackbar', { color: 'red', text: 'Which platform are you targeting?' })
        }
        else if (!this.participant_share) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
        }
        else if (this.participant_share.startsWith('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'enter nubmer like 0.5' })
        }
        else if ( this.link.length > 100) {
          this.$store.commit('snackbar', { color: 'red', text: 'Link must contain less than 100 characters.' })
        }
        else {
          this.create_campaign()
        }
      }

      // 
      else if (this.type === '7') {
        console.log(this.voucher_type);
        if (!this.link || this.link.includes(' ') || !this.link.includes('.')) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
        }
        else if (!this.platform) {
          this.$store.commit('snackbar', { color: 'red', text: 'Which platform are you targeting?' })
        }
        else if (!this.free && !this.discount) {
          this.$store.commit('snackbar', { color: 'red', text: 'Please enter a free item or a discount amount.' })
        }
        else if ( this.voucher_type === 'discount' && this.discount >= 100 ||  this.voucher_type === 'discount' && this.discount <= 0) {
          this.$store.commit('snackbar', { color: 'red', text: 'Discount must be between 1% to 99%.' })
        }
        else if (this.link.length > 100) {
          this.$store.commit('snackbar', { color: 'red', text: 'Link must contain less than 100 characters.' })
        }
        else {
          this.create_campaign()
        }
      }

      else {
        this.create_campaign()
      }

    },

    async create_campaign() {


      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: '/api/campaigns',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        data: {
          title: this.title,
          type: this.type,
          platform: this.platform,
          link: this.link ? 'https://' + this.link : '',
          participantsCount: this.participant_count,
          participantsShare: this.participant_share,
          endDate:this.end_date,
          // endDate:new Date(this.end_date).getTime() + 86400000 - 60000,
          conditions: this.condation,
          item: this.free,
          discount: this.discount,
        }
      })
        .then((res) => {
          console.log(res.data);
          this.$store.commit('snackbar', { color: 'green', text: 'Campaign created.' })
          this.$store.commit('update_info', { wallet: res.data.data.credit })

          this.camp_dialog = false
          if (localStorage.getItem('role') == 1) {
            this.$router.push('/venue_campaigns')
          }
          else {
            this.$router.push('/business_campaigns')
          }
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    empty_voucher_type() {
      if (this.voucher_type === 'free') {
        this.discount = ''
      }
      else {
        this.free = ''
      }

    },

    num(num, type) {
      if (type === 'count') {
        this.participant_count = (+num).toFixed(2)
      }
      if (type === 'share') {
        this.participant_share = (+num).toFixed(2)
      }
    },

    open_link(link) {
      window.open(link, '_blank')
    },

  }
};