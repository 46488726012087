import axios from "axios";
import { socket } from "@/socket";

export default {

    data: () => ({
        show_form: true,
        show_otp: false,
        email: "",
        password: "",
        show1: false,
    }),

    mounted() {
        document.getElementById('logo').click()
        window.scrollTo(0, 0);
        localStorage.clear()
        this.disconnect()
        // location.reload()
    },

    methods: {
        async login() {

            this.$store.commit('progress_status', true)
            await axios({
                method: 'POST',
                url: '/api/signin',
                data: {
                    email: this.email,
                    password: this.password,
                }
            })
                .then((res) => {
                    console.log(res.data);
                    localStorage.setItem('token', res.data.data.accessToken)
                    localStorage.setItem('role', res.data.data.role)
                    localStorage.setItem('user_login_id', res.data.data.id)
                    // this.socket_signin()
                    if (res.data.data.role === 1) {
                        this.$router.push('/venue_home')
                        location.reload()
                    }
                    if (res.data.data.role === 2) {
                        this.$router.push('/business_home')
                        location.reload()
                    }
                    if (res.data.data.role === 3) {
                        this.$router.push('/home')
                        location.reload()
                    }


                })
                .catch((err) => {
                    console.log(err);
                     if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })

        },

        disconnect() {
            socket.disconnect();
        },
        // socket_signin() {
        //     socket.emit("USER:SIGNIN", localStorage.getItem('token'), () => {
        //         console.log("emited");
        //     });
        //     console.log("USER:SIGNIN");
        // },

    }
};