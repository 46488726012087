import axios from "axios";


export default {


  data: () => ({
    sliders: '',
    user: {},
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_slider()
    this.get_user()
  },

  methods: {
    async get_slider() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/announcements',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          console.log(res.data.data);
          this.sliders = res.data.data.announcements

        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async get_user() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/users/me',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          console.log(res.data.data.user);
          this.user = res.data.data.user

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    open_link(link) {
      window.open(link, '_blank')
    }

  }
};