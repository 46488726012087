
// generel
import signin from './pages/general/signin/signin.vue'
import signup from './pages/general/signup/signup.vue'
import forget_password from './pages/general/forget_password/forget_password.vue'
import reset_password from './pages/general/reset_password/reset_password.vue'
import profile from './pages/general/profile/profile.vue'
import edit_profile from './pages/general/edit_profile/edit_profile.vue'
import terms from './pages/general/terms/terms.vue'
import privacy from './pages/general/privacy/privacy.vue'

import payment_result from './pages/general/payment_result/payment_result.vue'



// customers
import home from './pages/customers/home/home.vue'
import my_wallet from './pages/customers/my_wallet/my_wallet.vue'
import my_energy from './pages/customers/my_energy/my_energy.vue'
import venues from './pages/customers/venues/venues.vue'
import venue from './pages/customers/venue/venue.vue'
import payment from './pages/customers/payment/payment.vue'
import venues_campaigns from './pages/customers/venue_campaigns/venue_campaigns.vue'
import venue_menu from './pages/customers/venue_menu/venue_menu.vue'
import visit_us from './pages/customers/visit_us/visit_us.vue'
import my_activity from './pages/customers/my_activity/my_activity.vue'
import campaings from './pages/customers/campaings/campaings.vue'
import games from './pages/customers/games/games.vue'
import my_rewards from './pages/customers/my_rewards/my_rewards.vue'

// business
import credit from './pages/business/credit/credit.vue'
import new_campaign from './pages/business/new_campaign/new_campaign.vue'
import create_campaign from './pages/business/create_campaign/create_campaign.vue'
// import increase_follower from './pages/business/increase_follower/increase_follower.vue'
// import increase_view from './pages/business/increase_view/increase_view.vue'
// import create_content from './pages/business/create_content/create_content.vue'
// import share_social from './pages/business/share_social/share_social.vue'
import business_campaigns from './pages/business/business_campaigns/business_campaigns.vue'
import campaign_details from './pages/business/campaign_details/campaign_details.vue'
import business_home from './pages/business/business_home/business_home.vue'
import messages from './pages/business/messages/messages.vue'

// venues
import menu from './pages/venues/menu/menu.vue'
import new_venue_campaign from './pages/venues/new_venue_campaign/new_venue_campaign.vue'
import payments from './pages/venues/payments/payments.vue'
import venue_home from './pages/venues/venue_home/venue_home.vue'
import venue_campaigns from './pages/venues/venue_campaigns/venue_campaigns.vue'
import venue_credit from './pages/venues/venue_credit/venue_credit.vue'



export const Routes = [
    {
        path: '/',
        redirect: '/signin',
    },

    // general
    {
        path: '/signin',
        name: "g_signin",
        component: signin,
    },
    {
        path: '/signup',
        name: "g_signup",
        component: signup,
    },
    {
        path: '/forget_password',
        name: "g_forget_password",
        component: forget_password,
    },
    {
        path: '/reset_password',
        name: "g_reset_password",
        component: reset_password,
    },
    {
        path: '/profile',
        name: "profile",
        component: profile,
    },
    {
        path: '/edit_profile',
        name: "edit_profile",
        component: edit_profile,
    },
    {
        path: '/terms',
        name: "g_terms",
        component: terms,
    },
    {
        path: '/privacy',
        name: "g_privacy",
        component: privacy,
    },
    {
        path: '/payment_result',
        name: "g_payment_result",
        component: payment_result,
    },
   

    // customers
    {
        path: '/home',
        name: "home",
        component: home,
    },
    {
        path: '/my_wallet',
        name: "cs_my_wallet",
        component: my_wallet,
    },
    {
        path: '/my_energy',
        name: "cs_my_energy",
        component: my_energy,
    },
    {
        path: '/venues',
        name: "cs_venues",
        component: venues,
    },
    {
        path: '/venue',
        name: "cs_venue",
        component: venue,
    },
    {
        path: '/payment',
        name: "cs_payment",
        component: payment,
    },
    {
        path: '/venues_campaigns',
        name: "cs_venues_campaigns",
        component: venues_campaigns,
    },
    {
        path: '/venue_menu',
        name: "cs_venue_menu",
        component: venue_menu,
    },
    {
        path: '/visit_us',
        name: "cs_visit_us",
        component: visit_us,
    },
    {
        path: '/my_activity',
        name: "cs_my_activity",
        component: my_activity,
    },
    {
        path: '/campaings',
        name: "cs_campaings",
        component: campaings,
    },
    {
        path: '/games',
        name: "games",
        component: games,
    },
    {
        path: '/my_rewards',
        name: "cs_my_rewards",
        component: my_rewards,
    },
   

    // business
    {
        path: '/business_home',
        name: "business_home",
        component: business_home,
    },
    {
        path: '/credit',
        name: "bs_credit",
        component: credit,
    },
    {
        path: '/new_campaign',
        name: "bs_new_campaign",
        component: new_campaign,
    },
    {
        path: '/create_campaign',
        name: "bs_create_campaign",
        component: create_campaign,
    },
    // {
    //     path: '/increase_follower',
    //     name: "bs_increase_follower",
    //     component: increase_follower,
    // },
    // {
    //     path: '/increase_view',
    //     name: "bs_increase_view",
    //     component: increase_view,
    // },
    // {
    //     path: '/create_content',
    //     name: "bs_create_content",
    //     component: create_content,
    // },
    // {
    //     path: '/share_social',
    //     name: "bs_share_social",
    //     component: share_social,
    // },
    {
        path: '/business_campaigns',
        name: "bs_business_campaigns",
        component: business_campaigns,
    },
    {
        path: '/campaign_details',
        name: "bs_campaign_details",
        component: campaign_details,
    },
    {
        path: '/messages',
        name: "bs_messages",
        component: messages,
    },
    

    // venues
    {
        path: '/venue_home',
        name: "venue_home",
        component: venue_home,
    },
    {
        path: '/menu',
        name: "vn_menu",
        component: menu,
    },
    {
        path: '/new_venue_campaign',
        name: "vn_new_venue_campaign",
        component: new_venue_campaign,
    },
    {
        path: '/payments',
        name: "vn_payments",
        component: payments,
    },
    {
        path: '/venue_campaigns',
        name: "vn_venue_campaigns",
        component: venue_campaigns,
    },
    {
        path: '/venue_credit',
        name: "vn_venue_credit",
        component: venue_credit,
    },
   
]