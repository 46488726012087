import axios from "axios";


export default {
    

    data: () => ({
        cat_dialog: false,
        food_dialog: false,
    }),
  
    mounted() { window.scrollTo(0,0);
    
    },
  
    methods:{
       
    }
  };