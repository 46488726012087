import axios from "axios";


export default {


  data: () => ({
   menu : [],
   ingredients : '',
   image :'',
   ingredients_dialog : false,
   image_dialog : false,
   venue_membership : '',
  }),

  mounted() { window.scrollTo(0,0);
    this.get_menu()
    this.venue_membership= localStorage.getItem('venue_membership')

  },

  methods: {

    // categorys

    async get_menu() {
      console.log(this.catname);
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/menus/' + this.$route.query.id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        // data: {
        //   name: this.catname,
        // }
      })
        .then((res) => {
          console.log(res.data.data.menu.categories);
          // this.$store.commit('snackbar', { color: 'green', text: 'Campaign created' })
        //   this.categories = res.data.data.menu.categories
          this.menu = res.data.data.menu.categories

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },


  


  }
};