import axios from "axios";

export default {

  data: () => ({
    messages: {},
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_messages()
  },

  methods: {
    async get_messages() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/messages',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          console.log(res.data.data);
          this.messages = res.data.data.messages

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },
    
  }
};