import axios from "axios";

export default {

  data: () => ({
    new_password: "",
    repeat_new_password: "",
    dialog: false,
  }),

  mounted() { window.scrollTo(0,0);

  },

  methods: {


    async change_pass() {
      if (this.new_password !== this.repeat_new_password) {
        this.$store.commit('snackbar', { color: 'red', text: 'Passwords are not the same' })
      }
      else {
        this.$store.commit('progress_status', true)
        await axios({
          method: 'POST',
          url: 'api/change-pass',
          data: {
            token: this.$route.query.token,
            password: this.new_password,
          }
        })
          .then((res) => {
            console.log(res.data);
            // localStorage.setItem('token' , res.data.data.token)
            // this.$store.commit('snackbar', { color: 'green', text: res.data.message })
            // this.$router.push('/signin')
            this.dialog = true

          })
          .catch((err) => {
            console.log(err);
             if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

          })
          .finally(() => {
            this.$store.commit('progress_status', false)
          })
      }

    },

  }
};