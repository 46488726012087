import axios from "axios";

export default {

    data: () => ({
        show_rouls: true,
        show_form: false,
        checkbox: false,
        dialog: false,
        role: 1,

        cities: [
            'Melbourne',
            'Sydney',
            'Brisbane',
            'Perth',
            'Hobart',
            'Canberra',
            'Adelaide',
        ],
        countries: [
            'Australia',
        ],
        show1: false,

        // models
        name: "",
        email: "",
        password: "",
        phone: "",
        instagram_id: "",
        tiktok_id: "",
        country: "Australia",
        city: "Melbourne",
        bus_name: "",
        abn: "",
        venue_name: "",
        venue_address: "",
        website: "",
        verify_code: "",
    }),

    mounted() {
        window.scrollTo(0, 0);

    },

    methods: {
        send_form() {
            this.show_form = false
            this.show_rouls = false
        },

        async verify() {
            if (!this.email) {
                this.$store.commit('snackbar', { color: 'red', text: 'Enter your email' })
            }
            else {
                this.$store.commit('progress_status', true)
                await axios({
                    method: 'POST',
                    url: '/api/verify',
                    headers: {
                        token: localStorage.getItem("token")
                    },
                    data: {
                        email: this.email,
                    }
                })
                    .then((res) => {
                        console.log(res.data);
                        this.verify_code = ''
                        this.$store.commit('snackbar', { color: 'green', text: 'Please enter the verification code sent to your email to complete the process.' })

                    })
                    .catch((err) => {
                        console.log(err);
                         if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }
                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                    })
            }

        },

        validation(type) {

            if (!this.email || !this.name || !this.phone || !this.instagram_id ||
                !this.country || !this.city || !this.password
            ) {
                this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
            }
            else if (!this.email.includes('.') || !this.email.includes('@') || this.email.includes(' ')) {
                this.$store.commit('snackbar', { color: 'red', text: 'Email address is not valid.' })
            }
            else if (this.phone.length !== 10) {
                this.$store.commit('snackbar', { color: 'red', text: 'Phone number is not valid.' })
            }
            // else if (this.verify_code.length !== 6) {
            //     this.$store.commit('snackbar', { color: 'red', text: 'This code is not valid.' })
            // }

            // venue
            else if (this.role === 1) {
                if (!this.venue_name || !this.venue_address || !this.website || !this.abn) {
                    this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
                    console.log(this.role);
                }
                else if (!this.website.includes('.')) {
                    this.$store.commit('snackbar', { color: 'red', text: 'Please enter a valid link.' })
                }
                else if (this.abn.length !== 11) {
                    this.$store.commit('snackbar', { color: 'red', text: 'ABN must contain 11 digits.' })
                }
                else {
                    if (type === 'verify') {
                        this.verify()
                    }
                    else {
                        this.sign_up()
                    }
                }
            }

            // business
            else if (this.role === 2) {
                if (!this.bus_name || !this.abn) {
                    this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
                    console.log(this.role);
                }
                else if (this.abn.length !== 11) {
                    this.$store.commit('snackbar', { color: 'red', text: 'ABN must contain 11 digits.' })
                }
                else {
                    if (type === 'verify') {
                        this.verify()
                    }
                    else {
                        this.sign_up()
                    }
                }
            }
            else {
                if (type === 'verify') {
                    this.verify()
                }
                else {
                    this.sign_up()
                }
            }

        },

        async sign_up() {

            let business
            let venue

            if (this.role === 1) {
                business = undefined
                venue = {
                    abn: this.abn,
                    name: this.venue_name,
                    websiteLink: 'https://' + this.website,
                    address: this.venue_address
                }
            }
            else if (this.role === 2) {
                venue = undefined
                business = {
                    abn: this.abn,
                    name: this.bus_name,
                }
            }
            else {
                venue = undefined
                business = undefined
            }

            this.$store.commit('progress_status', true)
            await axios({
                method: 'POST',
                url: '/api/signup',
                headers: {
                    token: localStorage.getItem("token")
                },
                data: {
                    email: this.email,
                    name: this.name,
                    phone: this.phone,
                    instagramId: 'https://instagram.com/' + this.instagram_id,
                    tiktokId: this.tiktok_id ? 'https://tiktok.com/' + this.tiktok_id : undefined,
                    country: this.country,
                    city: this.city,
                    role: this.role,
                    password: this.password,
                    business,
                    venue,
                    token: this.verify_code,
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (this.role === 1 ) {
                        this.$store.commit('snackbar', { color: 'green', text: "You have successfully signed up. One of our team members will contact you to finalise your registration." })
                    }
                    else{
                        this.$store.commit('snackbar', { color: 'green', text: "You have successfully registered. You may now proceed to sign in." })
                    }

                    this.$router.push('/signin')
                })
                .catch((err) => {
                    console.log(err);
                     if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })



        },

        limit(lim, type) {
            if (type === 'abn') {
                // if (this.abn.length > lim) {
                let x = this.abn.slice(0, lim - 1)
                this.abn = x
                // }
            }
            else {
                if (this.phone.length > lim - 1) {
                    let p = this.phone.slice(0, -1)
                    this.phone = p
                }
            }
        },

        open_form(type) {
            this.show_form = true
            this.show_rouls = false
            this.role = type
            this.dialog = false
        },
    }
};