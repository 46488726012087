import axios from "axios";


export default {


  data: () => ({
    cat_dialog: false,
    food_dialog: false,
    delete_cat_dialog: false,
    delete_item_dialog: false,
    ingredients_dialog: false,
    image_dialog: false,


    cat_dialog_type: '',
    selected_cat: '',
    categories: [],

    item_dialog_type: '',
    selected_item: '',

    // form
    catname: "",
    name: "",
    price: "",
    ingredients: "",
    image: "",
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_category()
  },

  methods: {

    // categorys
    open_cat_dialog(type, id, name) {
      this.catname = ""
      this.cat_dialog = true
      this.cat_dialog_type = type
      this.selected_cat = id
      this.catname = name
    },

    open_delete_cat_dialog(id) {
      this.delete_cat_dialog = true
      this.selected_cat = id
    },

    async get_category() {
      console.log(this.catname);
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/menus/me',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        // data: {
        //   name: this.catname,
        // }
      })
        .then((res) => {
          // console.log(res.data);
          // this.$store.commit('snackbar', { color: 'green', text: 'Menu updated' })
          this.categories = res.data.data.menu.categories
          console.log(this.categories);

        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async add_category() {
      console.log(this.catname);

      if (!this.catname) {
        this.$store.commit('snackbar', { color: 'red', text: 'Please enter a category name.' })
      }

      else {
        this.$store.commit('progress_status', true)
        await axios({
          method: 'POST',
          url: '/api/menus/categories',
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token")
          },
          data: {
            name: this.catname,
          }
        })
          .then((res) => {
            console.log(res.data);
            this.categories = res.data.data.menu.categories
            // this.$store.commit('snackbar', { color: 'green', text: 'Menu updated' })
            this.cat_dialog = false

          })
          .catch((err) => {
            console.log(err);
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

          })
          .finally(() => {
            this.$store.commit('progress_status', false)
          })
      }
    },

    async edit_category() {

      if (!this.catname) {
        this.$store.commit('snackbar', { color: 'red', text: 'Please enter a category name.' })
      }
      else {
        this.$store.commit('progress_status', true)
        await axios({
          method: 'PUT',
          url: '/api/menus/categories/' + this.selected_cat,
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token")
          },
          data: {
            name: this.catname,
          }
        })
          .then((res) => {
            console.log(res.data);
            this.categories = res.data.data.menu.categories
            this.$store.commit('snackbar', { color: 'green', text: 'Item edited successfully.' })
            this.cat_dialog = false

          })
          .catch((err) => {
            console.log(err);
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

          })
          .finally(() => {
            this.$store.commit('progress_status', false)
          })
      }
    },

    async delete_category() {
      console.log(this.catname);
      this.$store.commit('progress_status', true)
      await axios({
        method: 'DELETE',
        url: '/api/menus/categories/' + this.selected_cat,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data);
          this.categories = res.data.data.menu.categories
          this.$store.commit('snackbar', { color: 'green', text: 'Delete successfully' })
          this.delete_cat_dialog = false
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },


    // foods

    open_item_dialog(type, cat, item) {
      // clear form
      this.name = ''
      this.price = ''
      this.ingredients = ''
      this.image = ''
      // document.getElementById('image').files = ''

      this.food_dialog = true
      this.item_dialog_type = type

      this.selected_cat = cat
      if (item) {
        this.selected_item = item._id
        this.name = item.name
        this.price = item.price
        this.ingredients = item.ingredients
        this.image = item.image
      }
    },

    open_delete_item_dialog(cat, item) {
      this.delete_item_dialog = true
      this.selected_cat = cat
      this.selected_item = item

    },

    async handleImageUpload(event, num) {
      const [file] = document.getElementById('image').files
      if (file) {
        document.getElementById('img').src = URL.createObjectURL(file)
      }
    },

    async send_image() {
      if (!this.name || !this.price) {
        this.$store.commit('snackbar', { color: 'red', text: 'Name and price are required.' })
      }
      else {
        const formData = new FormData();
        formData.append("image", document.getElementById('image').files[0]);

        this.$store.commit('progress_status', true)
        await axios({
          method: 'POST',
          url: '/api/images',
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token")
          },
          data: formData
        })
          .then((res) => {
            console.log(res.data);
            this.image = res.data.data.filename
            if (this.item_dialog_type === 'add') {
              console.log('add');
              this.add_item()
            }
            if (this.item_dialog_type === 'edit') {
              console.log('edit');
              this.edit_item()
            }
          })
          .catch((err) => {
            console.log(err);
            // this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
            if (this.item_dialog_type === 'add') {
              console.log('add');
              this.add_item()
            }
            if (this.item_dialog_type === 'edit') {
              console.log('edit');
              if (err.response.data.errors === 'empty') {
                this.edit_item()
              }
              else{
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

              }
            }
          })
          .finally(() => {
            this.$store.commit('progress_status', false)
          })

      }

    },

    async add_item() {

      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: '/api/menus/categories/' + this.selected_cat + '/items',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        data: {
          name: this.name,
          price: this.price,
          ingredients: this.ingredients,
          image: this.image,
        }
      })
        .then((res) => {
          console.log(res.data);
          this.categories = res.data.data.menu.categories
          this.$store.commit('snackbar', { color: 'green', text: 'Menu updated.' })
          this.food_dialog = false
          document.getElementById('image').value = ''

        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async edit_item() {
      console.log(this.catname);
      this.$store.commit('progress_status', true)
      await axios({
        method: 'PUT',
        url: '/api/menus/categories/' + this.selected_cat + '/items/' + this.selected_item,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        data: {
          name: this.name,
          price: this.price,
          ingredients: this.ingredients,
          image: this.image,
        }
      })
        .then((res) => {
          console.log(res.data);
          this.categories = res.data.data.menu.categories
          this.$store.commit('snackbar', { color: 'green', text: 'Item edited successfully.' })

          this.food_dialog = false
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async delete_item() {
      console.log(this.catname);
      this.$store.commit('progress_status', true)
      await axios({
        method: 'DELETE',
        url: '/api/menus/categories/' + this.selected_cat + '/items/' + this.selected_item,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data);
          this.categories = res.data.data.menu.categories
          this.$store.commit('snackbar', { color: 'green', text: 'Item deleted successfully.' })
          this.delete_item_dialog = false
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    fixed(num){
        this.price = (+num).toFixed(2)
      
    },
  }
};