import axios from "axios";

export default {
  data: () => ({
    user: {},
    dialog: false,
    amount: '',
    checkbox : false,
    withdraws:[],
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_user();
    this.get_withdraws()
  },

  methods: {
    async get_user() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/users/me",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.user = res.data.data.user;
          this.$store.commit("update_info", {
            credit: res.data.data.user.details.credit
          });
          console.log( this.user);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });0
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async get_withdraws() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/withdraws",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.withdraws = res.data.data.withdraws
        
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
          this.get_user();
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async send_withdraw() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "POST",
        url: "/api/withdraws",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data:{
          amount : this.amount
        }
      })
        .then((res) => {
          console.log(res.data.data);
          this.$store.commit("snackbar", {  
            color: "green",
            text: 'Successfully requested',
          });
          this.dialog = false
          this.get_withdraws()
          this.get_user()
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
          this.get_user();
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    cut(num) {
      let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return x.toFixed(2)
    },

    fixed(num) {
      this.amount = (+num).toFixed(2)

    },
  },
};