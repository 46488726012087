import axios from "axios";

export default {

  data: () => ({
    email: "",
  
  }),

  mounted() { window.scrollTo(0,0);

  },

  methods: {

    async forget_pass() {

      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: 'api/forget',
        // headers: {
        //     token: localStorage.getItem("token")
        // },
        data: {
          email: this.email,

        }
      })
        .then((res) => {
          console.log(res.data);
          // localStorage.setItem('token' , res.data.data.token)
          this.$store.commit('snackbar', { color: 'green', text: "Password reset link sent successfully to your email" })

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.erros })
          }
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },


  }
};