import axios from "axios";
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {

    components: {

        // map
        LMap,
        LTileLayer,
        LMarker,
        LIcon,

    },

    data: () => ({
        venue: {},

        // map
        apiKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5In0.eyJhdWQiOiIxOTEwOCIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5IiwiaWF0IjoxNjYwNTQ1MTI0LCJuYmYiOjE2NjA1NDUxMjQsImV4cCI6MTY2MzEzNzEyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.BllBK7n7RP8Dph2dRgDIz8mmNsd6SvgSFKz8vIYa_xTA4Nez3Z266a3a8OdipHNPHO-KbmBQgoaFBB6JHCIyA96eD7pYtKbX2ShkCcqnnASUQVyLu4rBHBnipQ2Eblam9QMUhXlIOFcEtdaLb7HgSTTinCCY1ZFi3Jf2YGdyW-FcxJjS32vuJ2TOY539lyofWpoptoraFQlQ11NP-O71qMnughSPpeS9DcClYKoeONhpydJEGuhjEzYCPs7yauD335g75tu27pAQpBhEYDqaWyZrOXeBzwm1AXHi7RhO28umCoSMyeAiP9MLtxxjnVwiuzTlm0n_GcisSOQRGuxx8A",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: '',
        zoom: 10,
        center: [-37.6825654, 144.9657008],
        markerLatLng: [-37.6825654, 144.9657008],

    }),

    mounted() {
        window.scrollTo(0, 0);
        this.get_venue()
    },

    methods: {
        async get_venue() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/api/venues/' + this.$route.query.id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.venue = res.data.data.venue
                    this.markerLatLng = [this.venue.latitude , this.venue.longitude]
                })
                .catch((err) => {
                    console.log(err);
                     if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },
    }
};