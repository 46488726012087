<template>
  <div>
    <v-bottom-navigation
      style="left: 50%; bottom: -18px; transform: translate(-50%, -50%)"
      color="primary"
      grow
      height="fit-content"
      fixed
      max-width="500px"
    >
      <v-btn to="/campaings"> <v-icon>mdi-gift</v-icon></v-btn>
      <v-btn to="/venues"><v-icon>mdi-silverware-fork-knife</v-icon></v-btn>
      <v-btn to="/games"><v-icon>mdi-puzzle</v-icon></v-btn>
      <v-btn to="/my_activity"> <v-icon>mdi-history</v-icon></v-btn>
      <v-btn to="/home" id="home"><v-icon>mdi-home</v-icon></v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.name === "home") {
      document.getElementById("home").click();
    }
  },
};
</script>
