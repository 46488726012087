import axios from "axios";


export default {

  data: () => ({
    camp_dialog: false,
    user: {},
   
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_user()
  },

  methods: {
    async get_user() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/users/me',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          console.log(res.data.data.user);
          this.user = res.data.data.user

      
           localStorage.setItem('instagramId' , this.user.instagramId)
          localStorage.setItem('tiktokId' , this.user.tiktokId)

          // bus
          this.name = this.user.name
          this.email = this.user.email
          this.phone = this.user.phone
          this.instagram_id = this.user.instagramId
          this.tiktok_id = this.user.tiktokId
          this.country = this.user.country
          this.city = this.user.city

          if (this.user.role === 2 ) {
            this.abn = this.user.details.abn
            this.business_name = this.user.details.name
            this.logo = this.user.details.logo
            localStorage.setItem('name' , this.user.details.name)
            localStorage.setItem('logo' , this.user.details.logo)
          }
          if (this.user.role === 1) {
            this.abn = this.user.details.abn
            this.venue_name = this.user.details.name
            this.logo = this.user.details.logo
            this.website = this.user.details.websiteLink
            this.venue_address = this.user.details.address
            localStorage.setItem('name' , this.user.details.name)
            localStorage.setItem('logo' , this.user.details.logo)
          }

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    logout(){
      localStorage.clear()
      this.$router.push('/')
    }

  }
};