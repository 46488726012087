import axios from "axios";


export default {

  data: () => ({
    camp_dialog: false,
    payments: [],
    vouchers: [],
    live_btn: 'grey',
    prev_btn: '',
  }),

  mounted() { window.scrollTo(0,0);
    this.get_payments()
  },

  methods: {

    async get_payments() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/payments',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data.data.payments);
          this.live_btn = 'grey'
          this.prev_btn = ''
          this.vouchers = []
          this.payments = res.data.data.payments
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async get_vouchers() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/vouchers',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data);
          this.live_btn = ''
          this.prev_btn = 'grey'
          this.payments = []
          this.vouchers = res.data.data.vouchers
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

  }

}