import axios from "axios";

export default {
  data: () => ({
    payments_dialog: false,
    vouchers_dialog: false,
    user: {},

    series: [0],
    chartOptions: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Energy"],
    },
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_user();
  },

  methods: {
    async get_user() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/users/me",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data.data.user);
          this.user = res.data.data.user;
          this.$store.commit("update_info", {
            wallet: res.data.data.user.wallet,
            energy: res.data.data.user.energy,
            level: res.data.data.user.level,
          });
          this.series = [res.data.data.user.energy];
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push("/signin");
          } else {
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          }
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },
  },
};
