<template>
    <div>
      <v-card class="py-3 rounded-b-xxl" elevation="5">
        <v-btn @click="$router.go(-1)" absolute top left depressed small icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
        <router-link to="/business_home"
          ><center>
            <img width="50%" src="../assets/images/logo.png" alt="" /></center
        ></router-link>
  
        <div class="d-flex align-center justify-space-around">
  
          <v-card flat class="d-flex flex-column align-center justify-center" to="">
            <span class="primary--text">My Level</span>
            <span class="gray--text">Basic</span>
          </v-card>
  
          <v-card flat class="d-flex flex-column align-center justify-center"  to="/credit">
            <span class="primary--text">Available Credit</span>
            <span class="gray--text">$ {{cut($store.state.wallet)}}</span>
          </v-card>
  
        </div>
      </v-card>
    </div>
  </template>

<script>
import axios from "axios";

export default {
  data: () => ({
    user: {},
  }),

  mounted() { window.scrollTo(0,0);
    this.get_user();
  },

  methods: {
    async get_user() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/users/me",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data.data.user);
          this.user = res.data.data.user;
          localStorage.setItem('name' , this.user.details.name)
          localStorage.setItem('logo' , this.user.details.logo)
           localStorage.setItem('instagramId' , this.user.instagramId)
          localStorage.setItem('tiktokId' , this.user.tiktokId)
          this.$store.commit('update_info', { wallet: res.data.data.user.details.credit})
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    cut(num) {
            let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            return x.toFixed(2)
          }
  },
};
</script>