<template>
  <div>
    <v-bottom-navigation style="left: 50%;bottom: -18px;
transform: translate(-50%, -50%);" color="primary" grow height="fit-content" fixed max-width="500px">
      <v-btn to="/new_campaign"
      ><v-icon>mdi-plus-box</v-icon></v-btn
      >
      <v-btn to="/business_campaigns"
      ><v-icon>mdi-gift</v-icon></v-btn
      >
      <v-btn to="/profile"> <v-icon>mdi-account</v-icon></v-btn>
      <v-btn to="/business_home" id="home"> <v-icon>mdi-home</v-icon></v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.name === 'business_home') {
      document.getElementById('home').click()
    }
  },

};
</script>
