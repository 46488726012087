import axios from "axios";


export default {

    data: () => ({
        checkbox: false,
        wallet: '',
        pocket: '',
        credit: {},
        vouchers: [],
        venue_name: '',

    }),

    mounted() {
        window.scrollTo(0, 0);
        this.get_credit()
        this.get_vouchers()
        this.venue_name = localStorage.getItem('venue_name')
    },

    methods: {
        async get_credit() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/api/payments/' + this.$route.query.id + '/credit',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.credit = res.data.data.user
                })                    // this.$store.commit('update_info', { wallet: this.credit.wallet })

                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        this.$router.push('/signin')
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    }
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async get_vouchers() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/api/vouchers/',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    notUsed: 1,
                    venue: this.$route.query.id
                }
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.vouchers = res.data.data.vouchers
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        this.$router.push('/signin')
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    }
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async pay(id) {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'POST',
                url: '/api/payments/',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    wallet: this.wallet || '0.00',
                    pocket: this.pocket || '0.00',
                    venueID: this.$route.query.id,
                    redeemID: id,
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.get_credit()
                    // this.credit = res.data.data.payment
                    // this.$store.commit('update_info', { wallet: this.credit.wallet })

                    // this.wallet = '0.00'
                    // this.pocket = '0.00'
                    this.$store.commit('snackbar', { color: 'green', text: 'Successfully paid' })
                    // this.get_vouchers()
                    this.$router.push('/my_wallet')
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        this.$router.push('/signin')
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    }
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        fixed(num, type) {
            if (type === 'wallet') {
                this.wallet = (+num).toFixed(2)
            }
            if (type === 'pocket') {
                this.pocket = (+num).toFixed(2)
            }
        },

        cut(num) {
            let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            return x.toFixed(2)
        }
    }
};