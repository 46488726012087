import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    // PUBLIC
    progress: false,
    network_err: false,
    snackbar_status: false,
    snackbar_text: false,
    snackbar_color: "green",

    // TOP INFOsnackbar_text : false,
    wallet: 0,
    energy: 0,
    level: 0,
    credit: 0,
    membership: "",
  },

  mutations: {
    // PUBLIC
    progress_status(state, payload) {
      state.progress = payload;
    },

    network_dialog(state, payload) {
      state.network_err = payload;
    },

    snackbar(state, payload) {
      state.snackbar_status = true;
      state.snackbar_text = payload.text;
      state.snackbar_color = payload.color;
    },

    update_info(state, payload) {
      state.wallet = payload.wallet;
      state.energy = payload.energy;
      state.level = payload.level;
      state.credit = payload.credit;
      state.membership = payload.membership;
    },
  },
});
