import axios from "axios";


export default {

  data: () => ({
    camp_dialog: false,
    campaigns: [],
    selected: {},
    venue: {},
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_campaigns()

  },

  methods: {
    async get_campaigns() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/venues/' + this.$route.query.id + '/campaigns',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        // data: {
        //   name: this.catname,
        // }
      })
        .then((res) => {
          console.log(res.data.data);
          // this.$store.commit('snackbar', { color: 'green', text: 'Campaign created' })
          //   this.categories = res.data.data.menu.categories
          this.campaigns = res.data.data.campaigns
          this.venue = res.data.data.venue.details

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },


    async accept(id) {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: '/api/campaigns/' + id + '/accept',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        params: {
          page: this.page,
          limit: 5
        }
      })
        .then((res) => {
          console.log(res.data.data);
          // this.campaigns = res.data.date
          this.$store.commit('snackbar', { color: 'green', text: 'You joined a new campaign.' })
          this.camp_dialog = false

          this.get_campaigns()

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async view(id) {
      await axios({
        method: 'GET',
        url: '/api/campaigns/' + id + '/view',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },

      })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    open_dialog(info) {
      this.camp_dialog = true
      this.selected = info
      this.view(info._id)

    },

    open_link(link) {
      window.open(link, '_blank')
    },
  }
};