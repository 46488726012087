import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    // rtl: true,
      theme: {
          themes: {
            light: {
              primary: '#FF0066',
              secondary: '#EF9A9A',
              pink2 : "#EB4486",
              pink3 : "#EDACEE",
              green : "#64D767",
              yellow : '#FFC400',
              lighten: '#EDE7F6',
              accent: '#8c9eff',
              error: '#b71c1c',
            },
            dark:{
              // secondary:'#EDE7F6',
            }
          },
        },
  });
  