import axios from "axios";


export default {

    data: () => ({
        camp_dialog: false,

       
    }),
  
    mounted() { window.scrollTo(0,0);
    
    },
  
    methods:{
     
    }
  };