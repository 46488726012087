<template>
  <v-app>
    <div id="back">
      <div id="main_app" style="position: relative">
        <!-- top info -->
        <div style="position: sticky; top: 0px; width: 100%; z-index: 100">
          <top_info v-if="role === '3' && show_navs && !$route.name.includes('home')"></top_info>
          <venues_top_info v-if="role === '1' && show_navs"></venues_top_info>
          <business_top_info v-if="role === '2' && show_navs && !$route.name.includes('g_payment_result')">
          </business_top_info>
        </div>

        <!-- <v-main> -->
        <router-view id="main" :class="!$route.name.includes('g_') ? 'pb-11' : ''"></router-view>
        <!-- </v-main> -->

        <!-- fix bottom nav -->
        <div id="bottom-menu">
          <bottom_nav v-if="role === '3' && show_navs"></bottom_nav>
          <business_bottom_nav v-if="role === '2' && show_navs && !$route.name.includes('g_payment_result')">
          </business_bottom_nav>
          <venues_bottom_nav v-if="role === '1' && show_navs"></venues_bottom_nav>
        </div>

        <!-- componnet -->
        <snackbar></snackbar>
        <progressBar></progressBar>
      </div>
    </div>
  </v-app>
</template>

<style>
#back {
  background: linear-gradient(-45deg,
      #ff006678,
      #7d10d57e,
      #ff006686,
      #0e87cd6f);
  background-size: 400% 400%;
  animation: gradient 60s ease infinite;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
}

#main_app {
  text-align: center;
  min-width: 100%;
  min-height: 100vh;
  position: relative;
  background: #fff;
  overflow: scroll;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media screen and (min-width: 500px) {
  #main_app {
    min-width: 500px;
    max-width: 500px;
  }

  #bottom-menu {
    /* position: fixed !important; */
    /* top: 96%;*/
    /* right: 500px;  */
    /* position: relative;
    right: 0px;
    width: 500px !important;
    z-index: 100;
    overflow: hidden; */
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8989899f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
</style>

<script>
import bottom_nav from "./components/bottom_nav";
import business_bottom_nav from "./components/business_bottom_nav.vue";
import venues_bottom_nav from "./components/venues_bottom_nav.vue";

import top_info from "./components/top_info.vue";
import venues_top_info from "./components/venues_top_info.vue";
import business_top_info from "./components/business_top_info.vue";

import snackbar from "./components/snackbar.vue";
import progressBar from "./components/progressBar.vue";

import { socket } from "@/socket";

export default {
  name: "App",

  components: {
    bottom_nav,
    business_bottom_nav,
    venues_bottom_nav,

    top_info,
    venues_top_info,
    business_top_info,

    snackbar,
    progressBar,
  },

  data: () => ({
    role: "",
    show_navs: false,
    token: "",
  }),

  // watchers

  watch: {
    // whenever question changes, this function will run
    $route: function (newPage, oldPage) {
      // console.log(newPage.name);
      console.log(this.$route.fullPath.includes("out"));

      if (
        newPage.name === "g_signin" ||
        newPage.name === "g_signup" ||
        newPage.name === "g_forget_password" ||
        newPage.name === "g_reset_password" ||
        newPage.name === "g_terms" ||
        newPage.name === "g_privacy" ||
        newPage.name === "g_payment_result"
      ) {
        this.show_navs = false;
        // console.log(this.show_navs);
      } else {
        this.show_navs = true;
        // console.log(this.show_navs);

        // no token redirect
        this.token = localStorage.getItem("token");
        if (this.token === undefined || this.token === null) {
          // console.log("auth");
          this.$router.push("/signin").catch(() => { });
        }
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    this.token = localStorage.getItem("token");
    this.role = localStorage.getItem("role");

    if (
      this.$route.name === "g_signin" ||
      this.$route.name === "g_signup" ||
      this.$route.name === "g_forget_password" ||
      this.$route.name === "g_terms" ||
      this.$route.name === "g_privacy" ||
      this.$route.name === "g_reset_password"
    ) {
      this.show_navs = false;
      // console.log(this.show_navs);
    } else {
      this.show_navs = true;
      // console.log(this.show_navs);

      // no token redirect
      this.token = localStorage.getItem("token");
      if (this.token === undefined || this.token === null) {
        console.log("auth");
        this.$router.push("/signin").catch(() => { });
      } else {
        this.socket_signin();
      }
    }
  },

  methods: {
    socket_signin() {
      socket.emit(
        "USER:SIGNIN",
        { userId: localStorage.getItem("user_login_id") },
        () => {
          console.log("emited");
        }
      );
      console.log("USER:SIGNIN");
    },
  },
};
</script>
