import { io } from "socket.io-client";

export const state = {
  connected: false,
  fooEvents: [],
  barEvents: []
};


export const socket = io('https://server.maxinify.com');

socket.on("connect", () => {
  state.connected = true;
  console.log('connected');
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("USER:SIGNIN", (...args) => {
  state.fooEvents.push(args);
});

socket.on("bar", (...args) => {
  state.barEvents.push(args);
});