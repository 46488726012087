<template>
  <div>
    <v-bottom-navigation style="left: 50%;bottom: -18px;
transform: translate(-50%, -50%);" color="primary" grow height="fit-content" fixed max-width="500px">
      <v-btn to="/menu"> <v-icon>mdi-hamburger</v-icon></v-btn>
      <v-btn to="/new_venue_campaign"><v-icon>mdi-plus-box</v-icon></v-btn>
      <v-btn to="/venue_campaigns"><v-icon>mdi-gift</v-icon></v-btn>
      <v-btn to="/payments"> <v-icon>mdi-cash</v-icon></v-btn>
      <v-btn to="/venue_home" id="home"><v-icon>mdi-home</v-icon></v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.name === 'venue_home') {
      document.getElementById('home').click()
    }
  },

};
</script>