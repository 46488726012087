import axios from "axios";


export default {


  data: () => ({
    camp_dialog: false,
    campaigns: [],
    new_campaigns: [],
    page: 1,
    pages: 0,
    disable: false,
    selected: {},
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_campaigns()
    this.pagination()
  },

  methods: {

    pagination() {
      
      document.getElementById("loader").addEventListener("scroll", (event) => {
        if ((event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight && this.new_campaigns.length !== 0) {
          this.page++
          console.log(this.page);
          this.get_campaigns()
        }
      });
    },

    async get_campaigns() {
      window.scrollTo(0, 0);

      console.log(this.page);
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/campaigns',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        params: {
          page: this.page,
          limit: 10
        }
      })
        .then((res) => {
          console.log(res.data.data.campaigns);
          this.pages = Math.ceil( res.data.data.total / 10)

          this.new_campaigns = []

          // console.log(res.data.data);
          // this.campaigns = res.data.data
          // this.pages =  Math.ceil(this.campaigns.total/5)

          if (this.page === 1) {
            this.campaigns = res.data.data.campaigns
            this.new_campaigns = res.data.data.campaigns
            document.getElementById("loader").scrollTo(0, 0);
          }
          if (this.page !== 1) {
            if (res.data.data.campaigns.length === 0) {
              this.new_campaigns = []
            }
            else {
              this.new_campaigns = this.campaigns.concat(res.data.data.campaigns);
              // let posiotion = document.body.offsetHeight - 10

              this.campaigns = this.new_campaigns
              // window.scrollTo(0, posiotion);
              console.log("!=1");
            }
            console.log(this.campaigns);
          }
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async get_campaign(id) {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/campaigns/' + id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        params: {
          page: this.page,
          limit: 5
        }
      })
        .then((res) => {
          console.log(res.data.data);
          this.campaigns = res.data.data

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async accept(id) {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: '/api/campaigns/' + id + '/accept',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        params: {
          page: this.page,
          limit: 5
        }
      })
        .then((res) => {
          console.log(res.data.data);
          // this.campaigns = res.data.data
          this.$store.commit('snackbar', { color: 'green', text: 'You joined a new campaign.' })
          this.camp_dialog = false
          // this.campaigns = []
          // this.new_campaigns = []
          document.getElementById('camp'+id).classList.remove('d-flex')
          document.getElementById('camp'+id).classList.add('d-none')
          this.get_campaigns()

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async view(id) {
      await axios({
        method: 'GET',
        url: '/api/campaigns/' + id + '/view',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
       
      })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    open_dialog(info) {
      this.camp_dialog = true
      this.selected = info
      this.view(info._id)
    },

    open_link(link) {
      window.open( link, '_blank')
    }
  }
};