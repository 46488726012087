import axios from "axios";


export default {


  data: () => ({
    camp_dialog: false,
    campaigns: [],
    new_campaigns: [],
    page: 1,
    pages: 0,
    live_btn: 'grey',
    prev_btn: '',
    card_color: 'yellow',
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_live_camp()
    this.pagination()
  },

  methods: {

     pagination() {
      document.getElementById("loader").addEventListener("scroll", (event) => {
        if ((event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight && this.new_campaigns.length !== 0) {
          this.page++
          console.log(this.page);
          this.get_live_camp()
        }
      });
    },

    async get_live_camp() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/campaigns/live',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        params:{
          page : this.page
        }
      })
        .then((res) => {
          // console.log(res.data);
          this.live_btn = 'grey'
          this.prev_btn = ''
          this.card_color = 'yellow'
          // this.campaigns = res.data.data.campaigns

          this.pages = Math.ceil(res.data.data.total / 10)
          this.new_campaigns = []


          if (this.page === 1) {
            this.campaigns = res.data.data.campaigns
            this.new_campaigns = res.data.data.campaigns
            document.getElementById("loader").scrollTo(0, 0);
          }
          if (this.page !== 1) {
            if (res.data.data.campaigns.length === 0) {
              this.new_campaigns = []
            }
            else {
              this.new_campaigns = this.campaigns.concat(res.data.data.campaigns);

              this.campaigns = this.new_campaigns
              console.log("!=1");
            }
            console.log(this.campaigns);
          }

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async get_prev_camp() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/api/campaigns/prev',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data);
          this.live_btn = ''
          this.prev_btn = 'grey'
          this.card_color = 'white'
          // this.campaigns = res.data.data.campaigns

          this.pages = Math.ceil(res.data.data.total / 10)
          this.new_campaigns = []


          if (this.page === 1) {
            this.campaigns = res.data.data.campaigns
            this.new_campaigns = res.data.data.campaigns
            document.getElementById("loader").scrollTo(0, 0);
          }
          if (this.page !== 1) {
            if (res.data.data.campaigns.length === 0) {
              this.new_campaigns = []
            }
            else {
              this.new_campaigns = this.campaigns.concat(res.data.data.campaigns);

              this.campaigns = this.new_campaigns
              console.log("!=1");
            }
            console.log(this.campaigns);
          }

        })
        .catch((err) => {
          console.log(err);
           if (err.response.status === 401) {
            this.$router.push('/signin')
          }
          else {
            this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
          }

        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

  }
};